import React from 'react';

const Paragraph = ({children, className}) => {

    return (
        <p className={`text-base lg:text-lg leading-6 lg:leading-7 font-normal${` `}${className}`}>
            {children}
        </p>
    )
}

export default Paragraph