import React from 'react';

const PageTitle = ({ children, className }) => {

    return (
        <h1 className={`text-5xl xl:text-6xl font-sans leading-10 font-bold tracking-tight${` `}${className}`}>
            {children}
        </h1>
    )
}

export default PageTitle